import {
  Alert,
  Button,
  Container,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import styles from "./App.module.scss";

const App = () => {
  const [learn, setLearn] = useState<string>("");
  const [know, setKnow] = useState<string>("");
  const [start, setStart] = useState<string>("");
  const outputParagraphRef = useRef<HTMLParagraphElement>(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const onClickCopyToClipboard = async () => {
    try {
      const textToCopy = outputParagraphRef.current?.textContent;

      if (!!textToCopy) {
        await navigator.clipboard.writeText(textToCopy);
        setIsSnackbarOpen(true);
      }
    } catch (e) {
      console.log(
        `Your browser doesn't support "copy to clipboard" functionality`,
      );
    }
  };

  const onClickCloseSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <Container>
      <Stack pt={4}>
        <form>
          <Stack gap={2}>
            <TextField
              label="What do you want to learn"
              onChange={(e) => setLearn(e.currentTarget.value)}
            />
            <TextField
              label="What do you already know"
              onChange={(e) => setKnow(e.currentTarget.value)}
            />
            <TextField
              label="What do you want to start with"
              onChange={(e) => setStart(e.currentTarget.value)}
            />
          </Stack>
        </form>
        <div>
          <h2>Preview</h2>
          <p ref={outputParagraphRef}>
            I would like to learn{" "}
            <span className={styles.highlight}>[{learn}]</span>. You will now
            act as my teacher. When asked questions you will provide real world
            examples and reference official documentations. I have knowledge
            about <span className={styles.highlight}>[{know}]</span>, so make
            sure to provide comparisons that will help me understand. Let's
            begin by detailing how to{" "}
            <span className={styles.highlight}>[{start}]</span>.
          </p>
        </div>
      </Stack>
      <Button onClick={onClickCopyToClipboard} variant="contained">
        Copy to clipboard
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={3000}
        onClose={onClickCloseSnackbar}
        open={isSnackbarOpen}
      >
        <Alert severity="success">Preview copied to clipboard</Alert>
      </Snackbar>
    </Container>
  );
};

export default App;
